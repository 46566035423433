import { Button, TextField } from "@material-ui/core";
import { useCatalog } from "context/catalog/CatalogContext";
import { useUi } from "context/ui/UIContext";
import { ICardItem } from "models/payment/ICard";
import { CreditRechargeRequest } from "models/wallet/IWallet";
import { PrivateHeader } from "pages/private/Components/privateHeader/PrivateHeader";
import React, { ChangeEvent, FC, useCallback, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { WalletApi } from "services/Api/wallet/WalletApi";
import PaymentTypeSelect from "./components/paymentTypeSelect/PaymentTypeSelect";
import styles from "./Recharge.module.scss";
import PixRecharge, { IPixHandler } from "./components/pixRecharge/PixRecharge";

export interface IRecharge {}

const Recharge: FC<IRecharge> = ({}) => {
  const { storeId, walletId } = useParams<{
    storeId: string;
    walletId: string;
  }>();
  const { catalog, onLoading } = useCatalog();
  const history = useHistory();
  const { toast, showLoading, hideLoading, toastFullScreen, isMobile } =
    useUi();

  const pixRef = useRef<IPixHandler>(null);

  const valueRef = useRef<HTMLInputElement>(null);

  const [rechargeValue, setRechargeValue] = useState("");
  const [paymentType, setpaymentType] = useState<"Pix" | "Credit" | null>(null);
  const [card, setCard] = useState<ICardItem | null>(null);

  const handlePaymentType = useCallback(
    (type: "Pix" | "Credit", _card: ICardItem | null) => {
      setpaymentType(type);
      setCard(_card);
    },
    []
  );

  const inputMoneyMask = useCallback(
    (value?: string) => {
      if (!value) {
        return undefined;
      }
      value = value.replace(/\D/g, "");
      while (value.length < 3) {
        value = "0" + value;
      }
      value = value.replace(/^0+(?=\d{3,})/, "");
      const formatWithThousandSeparator = (val: string) => {
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };
      const maskedValue =
        "R$ " +
        formatWithThousandSeparator(value.slice(0, -2)) +
        "," +
        value.slice(-2);

      if (value === "000") {
        if (valueRef && valueRef.current) {
          valueRef.current.value = "";
        }
        return undefined;
      }
      return maskedValue;
    },
    [valueRef]
  );

  const handleChangeValue = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = ev.target.value;
      value = value.replace(/[^\d]/g, "");
      value = value.slice(0, -2) + "," + value.slice(-2);
      value = value.split(",")[0].length === 0 ? "0" + value : value;

      const price = parseFloat(value.replace(",", "."));

      setRechargeValue(price === 0 ? "" : value);
    },
    []
  );

  const handleSubmitPayment = useCallback(async () => {
    const value = rechargeValue.length
      ? parseFloat(rechargeValue.replace(",", "."))
      : 0;

    if (value < 1) {
      toast("Recarga mínima R$1,00", "error");
    } else if (!paymentType) {
      toast("Selecione uma forma de pagamento", "error");
    } else {
      if (paymentType === "Pix") {
        try {
          await pixRef.current?.open(value, walletId, storeId);
          toastFullScreen(
            "Recarga efetuada com sucesso!",
            "check_circle_outline",
            "success",
            3000,
            () => history.goBack(),
            undefined,
            isMobile ? 150 : 250,
            isMobile ? 24 : 36
          );
        } catch (error) {
          if (!(error as string)?.includes("Cancelado pelo usuário")) {
            toast(error as string, "error");
          }
        }
      } else if (paymentType === "Credit") {
        if (!card) {
          toast("Selecione um cartão de crédito", "error");
        } else {
          const walletApi = WalletApi();
          showLoading();
          const rechargeOrder: CreditRechargeRequest = {
            Amount: value,
            CreditCardId: card.id,
            LocalClientId: storeId,
            WalletId: walletId,
          };
          walletApi
            .creditRecharge(rechargeOrder)
            .then((response) => {
              if (response.status === 200) {
                toastFullScreen(
                  "Recarga efetuada com sucesso!",
                  "check_circle_outline",
                  "success",
                  3000,
                  () => history.goBack(),
                  undefined,
                  isMobile ? 150 : 250,
                  isMobile ? 24 : 36
                );
              }
            })
            .finally(() => {
              hideLoading();
            });
        }
      }
    }
  }, [
    card,
    hideLoading,
    history,
    isMobile,
    paymentType,
    rechargeValue,
    showLoading,
    storeId,
    toast,
    toastFullScreen,
    walletId,
  ]);

  return (
    <div id={styles.Recharge}>
      <PrivateHeader
        onClickBack={history.goBack}
        title={onLoading ? "Wallet" : catalog?.name ?? "Wallet"}
      />
      <div
        className={styles.container}
        style={{
          width: isMobile ? "100%" : undefined,
          padding: isMobile ? "0px 20px" : undefined,
        }}
      >
        <h1>Recarga</h1>
        <div>
          <p>Valor da recarga</p>
          <TextField
            inputRef={valueRef}
            size="small"
            placeholder={"R$ 0,00"}
            value={inputMoneyMask(rechargeValue)}
            variant="standard"
            name={"value"}
            onChange={handleChangeValue}
          />
        </div>
        <div>
          <h1 style={{ fontSize: isMobile ? "22px" : undefined }}>
            Forma de pagamento
          </h1>
          <PaymentTypeSelect
            handlePaymentType={handlePaymentType}
            type={paymentType}
            card={card}
          />
          <Button variant="contained" fullWidth onClick={handleSubmitPayment}>
            Finalizar pagamento
          </Button>
        </div>
      </div>
      <PixRecharge ref={pixRef} />
    </div>
  );
};

export default Recharge;
