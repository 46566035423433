import { Button, Grid, IconButton, Modal, Paper } from "@material-ui/core";
import {
  AddCircle,
  DeleteOutline,
  InfoOutlined,
  CloseOutlined,
  StarBorder,
  StarRate,
} from "@material-ui/icons";
import { Skeleton } from "@mui/material";
import { useCatalog } from "context/catalog/CatalogContext";
import { useUi } from "context/ui/UIContext";
import { IUserProfile } from "models/wallet/IWallet";
import { PrivateHeader } from "pages/private/Components/privateHeader/PrivateHeader";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { WalletApi } from "services/Api/wallet/WalletApi";
import { hideCPF } from "utils/mask/cpf";
import { ReactComponent as Alert } from "assets/icons/alert.svg";
import styles from "./Profile.module.scss";
import CardApi from "services/Api/card/CardApi";
import AddressApi from "services/Api/address/AddressApi";
import { NewCard } from "pages/newCard/NewCard";
import { NewAddress } from "pages/newAddress/NewAddress";

export interface IProfile {}

const Profile: FC<IProfile> = ({}) => {
  const { catalog, onLoading: onLoadingCatalog } = useCatalog();
  const history = useHistory();
  const { isMobile, toast, showLoading, hideLoading } = useUi();

  const [onLoading, setOnLoading] = useState(false);
  const [loadingAddresses, setloadingAddresses] = useState(false);
  const [loadingCards, setLoadingCards] = useState(false);
  const [userProfile, setUserProfile] = useState<IUserProfile | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [openAddCard, setopenAddCard] = useState(false);
  const [newAdressOpen, setNewAdresOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    type: "CARD" | "ADRRESS";
    id: string;
  } | null>(null);

  const getAddresses = useCallback(async () => {
    try {
      setloadingAddresses(true);
      const response = await AddressApi.getUserAdresses();
      if (response.status === 200) {
        setUserProfile((prev) => {
          if (prev) {
            return {
              ...prev,
              Addresses: response.data,
            };
          } else {
            return null;
          }
        });
      }
    } finally {
      setloadingAddresses(false);
    }
  }, []);

  const getCards = useCallback(async () => {
    try {
      setLoadingCards(true);
      const response = await CardApi.getCards();
      if (response.status === 200) {
        setUserProfile((prev) => {
          if (prev) {
            return {
              ...prev,
              CreditCards: response.data,
            };
          } else {
            return null;
          }
        });
      }
    } finally {
      setLoadingCards(false);
    }
  }, []);

  const setDefaultAddress = useCallback(
    async (addressId: string) => {
      try {
        setloadingAddresses(true);
        await AddressApi.setDefaultAddress(addressId);
        getAddresses();
      } finally {
        setloadingAddresses(false);
      }
    },
    [getAddresses]
  );

  useEffect(() => {
    const walletApi = WalletApi();
    setOnLoading(true);
    walletApi
      .getProfile()
      .then((response) => {
        setUserProfile(response.data);
      })
      .catch(() => {
        history.goBack();
      })
      .finally(() => {
        setOnLoading(false);
      });
  }, [history]);

  const onClickDelete = useCallback((type: "CARD" | "ADRRESS", id: string) => {
    setItemToDelete({ type, id });
    setShowDeleteModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setItemToDelete(null);
    setShowDeleteModal(false);
  }, []);

  const onCloseAddNewCard = useCallback(() => {
    setopenAddCard(false);
    getCards();
  }, [getCards]);

  const onAddAdrress = useCallback(() => {
    getAddresses();
    setNewAdresOpen(false);
  }, [getAddresses]);

  const onConfirmDelete = useCallback(async () => {
    if (itemToDelete?.type === "CARD") {
      try {
        showLoading();
        const response = await CardApi.deleteCard(itemToDelete.id);
        console.log(response);
        if (response.status === 200) {
          getCards();
          toast("Cartão excluído com sucesso", "success");
          setShowDeleteModal(false);
        }
      } finally {
        hideLoading();
      }
    }
    if (itemToDelete?.type === "ADRRESS") {
      try {
        showLoading();
        const response = await AddressApi.deleteAddress(itemToDelete.id);
        console.log(response);
        if (response.status === 200) {
          getAddresses();
          toast("Endereço excluído com sucesso", "success");
          setShowDeleteModal(false);
        }
      } finally {
        hideLoading();
      }
    }
  }, [getAddresses, getCards, hideLoading, itemToDelete, showLoading, toast]);

  return (
    <div id={styles.Profile}>
      <PrivateHeader
        onClickBack={history.goBack}
        title={onLoadingCatalog ? "Wallet" : catalog?.name ?? "Wallet"}
      />
      <div className={styles.container}>
        <h1>Meus dados</h1>
        <p>
          <InfoOutlined />
          Caso necessite de alguma alteração entre em contato com o suporte
        </p>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7} className={styles.item}>
            <span>Nome</span>
            {onLoading ? (
              <Skeleton
                width={"100%"}
                height={45}
                variant="rectangular"
                style={{ borderRadius: 4 }}
              />
            ) : (
              <p>{userProfile?.Name}</p>
            )}
          </Grid>
          <Grid item xs={12} md={5} className={styles.item}>
            <span>CPF</span>
            {onLoading ? (
              <Skeleton
                width={"100%"}
                height={45}
                variant="rectangular"
                style={{ borderRadius: 4 }}
              />
            ) : (
              <p>{hideCPF(userProfile?.Document ?? "")}</p>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            className={styles.item}
            style={{ marginTop: isMobile ? 16 : 0 }}
          >
            <span>E-mail</span>
            {onLoading ? (
              <Skeleton
                width={"100%"}
                height={45}
                variant="rectangular"
                style={{ borderRadius: 4 }}
              />
            ) : (
              <p>{userProfile?.Email}</p>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            className={styles.item}
            style={{ marginTop: isMobile ? 16 : 0 }}
          >
            <span>Telefone</span>
            {onLoading ? (
              <Skeleton
                width={"100%"}
                height={45}
                variant="rectangular"
                style={{ borderRadius: 4 }}
              />
            ) : (
              <p>{userProfile?.Phone}</p>
            )}
          </Grid>
        </Grid>

        <h1>Cartões</h1>

        <Button variant="outlined" onClick={() => setopenAddCard(true)}>
          Adicionar cartão <AddCircle />
        </Button>

        {onLoading || loadingCards ? (
          <Skeleton
            width={"100%"}
            height={55}
            variant="rectangular"
            style={{ borderRadius: 4 }}
          />
        ) : (
          userProfile?.CreditCards.map((it, key) => (
            <div key={key} className={styles.listItem}>
              <div>{`${it.brandDescription} **** ${it.finalNumber}`}</div>
              <div>
                <IconButton onClick={() => onClickDelete("CARD", it.id)}>
                  <DeleteOutline />
                </IconButton>
              </div>
            </div>
          ))
        )}

        <h1>Endereços</h1>

        <Button variant="outlined" onClick={() => setNewAdresOpen(true)}>
          Adicionar endereço <AddCircle />
        </Button>

        {onLoading || loadingAddresses ? (
          <Skeleton
            width={"100%"}
            height={55}
            variant="rectangular"
            style={{ borderRadius: 4 }}
          />
        ) : (
          userProfile?.Addresses.map((it, key) => (
            <div key={key} className={styles.listItem}>
              <div>{`${it.street} ${it?.number ? `, ${it.number}` : ""} - ${
                it.neighborhood
              }, ${it.city} - ${it.state}`}</div>
              <div>
                {!!it.default ? (
                  <IconButton style={{ padding: 4 }}>
                    <StarRate
                      fontSize="medium"
                      style={{
                        width: 25,
                        height: 25,
                        color: "#FFB300",
                      }}
                    />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setDefaultAddress(it.id)}>
                    <StarBorder />
                  </IconButton>
                )}
                <IconButton onClick={() => onClickDelete("ADRRESS", it.id)}>
                  <DeleteOutline />
                </IconButton>
              </div>
            </div>
          ))
        )}
      </div>
      <Modal
        open={showDeleteModal}
        onClose={closeModal}
        className={styles.modal}
        style={{ padding: isMobile ? "0px" : undefined }}
      >
        <Paper
          className={styles.paper}
          style={{ width: isMobile ? "90%" : undefined }}
        >
          <div>
            <span>{`Excluir ${
              itemToDelete?.type === "ADRRESS" ? "endereço" : "cartão"
            }`}</span>
            <IconButton onClick={closeModal}>
              <CloseOutlined />
            </IconButton>
          </div>
          <Alert />
          <p>{`Deseja realmente excluir o ${
            itemToDelete?.type === "ADRRESS" ? "endereço" : "cartão"
          }`}</p>
          <Button variant="contained" color="inherit" onClick={onConfirmDelete}>
            Excluir
          </Button>
          <Button variant="outlined" color="inherit" onClick={closeModal}>
            Cancelar
          </Button>
        </Paper>
      </Modal>
      <Modal
        open={openAddCard}
        onClose={() => setopenAddCard(false)}
        className={styles.modalCardContainer}
      >
        <NewCard
          onCancel={() => onCloseAddNewCard()}
          onConfirm={() => onCloseAddNewCard()}
          title="Adicionar cartão"
        />
      </Modal>
      <Modal
        open={newAdressOpen}
        onClose={() => setNewAdresOpen(false)}
        className={styles.modalAdrressContainer}
      >
        <NewAddress
          showCloseIcon
          onSuccess={onAddAdrress}
          onCancel={() => setNewAdresOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default Profile;
