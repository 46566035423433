import axios from "axios";
import { IFacebookPixel } from "models/local/IFacebookPixel";
import { IStoreConfig, IStoreConfigLocal, LocalType } from "../../../models/local/ILocalConfig";
import { API_BASE_URL } from "cross-cutting/constants";
import api from "../Api";


const StoreApi = () => {
    const getConfig = async (localId: string): Promise<IStoreConfig> => {
        const result = await api.get<IStoreConfigLocal>(`/Local/${localId}`);
        let localType: LocalType | undefined = undefined

        if (localId === "2b5e694d-4ac0-dc94-0903-1edc5484758a") {//uiki parracho
            localType = LocalType.restaurant
        }

        return {
            storeId: result.data.StoreId,
            deliveryFee: result.data.DeliveryFee,
            allowPaymentOnDelivery: result.data.AllowPaymentOnDelivery,
            allowOnlinePayment: result.data.AllowOnlinePayment,
            showDeliveryStatus: result.data.ShowDeliveryStatus,
            allowCatchOnShop: result.data.AllowCatchOnShop,
            allowDeliveryHome: result.data.AllowDeliveryHome,
            allowCustomDelivery: result.data.AllowCustomDelivery,
            allowTableDelivery: result.data.AllowTableDelivery,
            customDeliveryDescription: result.data.CustomDeliveryDescription,
            acquirerType: result.data.AcquirerType, //Enum Adiquirente
            isMeepCard: result.data.IsMeepCard,
            acceptInstallment: result.data.AcceptInstallment,
            installment: result.data.Installment,
            useMeepDelivery: result.data.UseMeepDelivery,
            acceptSlipPayment: result.data.AcceptSlipPayment,
            disableAutenticateCard: true,
            localType: localType,
            serviceFee: result.data.ServiceFee
        };
    }

    const getFacebookPixelId = async (localId: string) => {
        const result = await axios.get<IFacebookPixel>(`${API_BASE_URL}/Local/FacebookPixelId?localId=${localId}`);
        return result.data.facebookPixelId;
    }

    return { getConfig, getFacebookPixelId }
}

export default StoreApi;