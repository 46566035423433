import React, { FC, useCallback, useState } from "react";
import styles from "./Home.module.scss";
import { Catalog } from "models/catalog/aggregates/Catalog";
import {
  LocationOnOutlined,
  ChevronRight,
  CloseOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import { ReactComponent as Www } from "assets/icons/www.svg";
import { ReactComponent as Whatsapp } from "assets/icons/whatsapp.svg";
import { ReactComponent as Instagram } from "assets/icons/instagram_b.svg";
import { ReactComponent as Facebook } from "assets/icons/facebook_b.svg";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { ReactComponent as WalletIcon } from "assets/icons/wallet.svg";
import { ReactComponent as WalletBlueIcon } from "assets/icons/wallet_blue.svg";
import { Button, IconButton, Modal, Paper } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useUi } from "context/ui/UIContext";

export interface IHome {
  catalog: Catalog;
  goToCatalog: () => void;
}

const Home: FC<IHome> = ({ catalog, goToCatalog }) => {
  const [showKnowWallet, setShowKnowWallet] = useState(false);
  const [showStores, setShowStores] = useState(false);
  const history = useHistory();
  const { url } = useParams<{ storeId: string; url: string }>();
  const { toast, isMobile } = useUi();

  const goToSite = useCallback(
    (link: string | null) => {
      if (!link) {
        toast("Sem site cadastrado no momento", "error");
      } else {
        const fullLink = link.startsWith("http") ? link : `https://${link}`;
        window.open(fullLink, "_blank");
      }
    },
    [toast]
  );

  const goToInstagram = useCallback(
    (link: string | null) => {
      if (!link) {
        toast("Sem instagram cadastrado no momento", "error");
      } else {
        window.open(
          `https://www.instagram.com/${link.replace(/^@/, "")}`,
          "_blank"
        );
      }
    },
    [toast]
  );

  const goToWhatsApp = useCallback(
    (link: string | null) => {
      if (!link) {
        toast("Sem whatsapp cadastrado no momento", "error");
      } else {
        window.open(`https://wa.me/${link}`, "_blank");
      }
    },
    [toast]
  );

  const goToFacebook = useCallback(
    (link: string | null) => {
      if (!link) {
        toast("Sem facebook cadastrado no momento", "error");
      } else {
        window.open(
          `https://www.facebook.com/${link.replace(/^@/, "")}`,
          "_blank"
        );
      }
    },
    [toast]
  );

  const goToWallet = useCallback(() => {
    history.push(`/${url}/${catalog.id}/wallet`);
  }, [catalog, history, url]);

  const _goToCatalog = useCallback(() => {
    history.push(`/${url}/${catalog.id}`);
    goToCatalog();
  }, [catalog.id, goToCatalog, history, url]);

  return (
    <div id={styles.Home} style={{ padding: isMobile ? "8px" : undefined }}>
      <div className={styles.header}>
        <img
          src={catalog.profileConfiguration?.logo}
          alt="logo"
          style={{
            width: isMobile ? "70px" : undefined,
            height: isMobile ? "70px" : undefined,
          }}
        />
        <div>
          <div
            style={{
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "start" : "center",
            }}
          >
            <div style={{ fontSize: isMobile ? "20px" : "35px" }}>
              {catalog.name}
            </div>

            <div>
              <Www
                onClick={() => goToSite(catalog.profileConfiguration?.siteUrl)}
              />
              <Whatsapp
                onClick={() =>
                  goToWhatsApp(catalog.profileConfiguration?.whatsapp)
                }
              />
              <Instagram
                onClick={() =>
                  goToInstagram(catalog.profileConfiguration?.instagram)
                }
              />
              <Facebook
                onClick={() =>
                  goToFacebook(catalog.profileConfiguration?.facebook)
                }
              />
            </div>
          </div>
          <div
            style={{
              fontSize: isMobile ? "11px" : undefined,
              marginTop: isMobile ? "6px" : undefined,
            }}
          >
            <LocationOnOutlined />
            {`${catalog.profileConfiguration?.addressLocal.street}, ${catalog.profileConfiguration?.addressLocal.number} - ${catalog.profileConfiguration?.addressLocal.neighborhood}, ${catalog.profileConfiguration?.addressLocal.city} - ${catalog.profileConfiguration?.addressLocal.uf}`}
          </div>
        </div>
      </div>
      <p className={styles.description}>
        {catalog.profileConfiguration?.description}
      </p>
      <div className={styles.actions}>
        <p>Clique em uma das opções</p>
        <div style={{ flexDirection: isMobile ? "column" : undefined }}>
          <div onClick={_goToCatalog}>
            <MenuIcon />
            <span>Cardápio digital</span>
            <ChevronRight />
          </div>
          <div onClick={goToWallet}>
            <WalletIcon />
            <span>Wallet </span>
            <ChevronRight />
          </div>
        </div>
        <span onClick={() => setShowKnowWallet(true)}>Conheça a wallet</span>
      </div>
      <Modal
        open={showKnowWallet}
        onClose={() => setShowKnowWallet(false)}
        className={styles.modal}
        style={{ padding: isMobile ? "0 24px" : undefined }}
      >
        <Paper className={styles.paper}>
          <div>
            <span>Conheça a Wallet</span>
            <IconButton onClick={() => setShowKnowWallet(false)}>
              <CloseOutlined />
            </IconButton>
          </div>
          <WalletBlueIcon />
          <div>
            <p>
              Com a Wallet, todo o saldo que você recarregar ficará armazenado
              na sua conta, ela pode ser vinculada a uma tag física, facilitando
              ainda mais suas transações.
            </p>
            <p>
              Assim, você pode utilizar a Wallet para suas compras, seja em
              maquininhas, nos totens de autoatendimento ou através do cardápio
              digital. Caso sobre algum valor, ele permanecerá disponível.
            </p>
            <span onClick={() => setShowStores((prev) => !prev)}>
              Ver estabelecimentos
              {showStores ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </span>
            {showStores && catalog.stores.map((it) => <h5>{it.name}</h5>)}
          </div>
          <Button variant="contained" color="inherit" onClick={goToWallet}>
            Começar
          </Button>
        </Paper>
      </Modal>
    </div>
  );
};

export default Home;
